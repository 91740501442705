.delete_container
{
    display: flex;
    position: fixed;

    z-index: 5;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0,0,0,.5);
}

.delete_content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    text-align: center;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 30px;
    background: #fff;
    color: #000;
    border-radius: 10px;
    height: fit-content;

    gap: 20px;

    width: 420px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.delete_content h2
{
    margin: 0;
    padding: 0;
}

.delete_controls
{
    display: flex;
    justify-content: center;

    width: 250px;
    gap: 30px;
}

.delete_controls a
{
    width: 50%;
}

.visitors_to_delete
{
    display: flex;
    justify-content: center;
    width: 100%;
    word-break: break-word;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .delete_content
    {
        width: 340px;
    }

    .delete_container
    {
        padding: 10px;
    }
}
