.container
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    gap: 15px;
}

.branch_card
{
    display: flex;
    flex-direction: column;
    width: 350px;
    color: #000;

    text-decoration: none;

    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(212,216,232,.60);

    transition: all 0.1s ease;
}

.branch_card:hover
{
    background: var(--btnColorHover);
    color: #fff;
}

.branch_info
{
    display: flex;
    flex-direction: column;

    padding: 10px;
}