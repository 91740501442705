.container
{
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 10px;
}

.container input[type="submit"]
{
    padding: 8px;
}

.container h1
{
    padding: 0;
    margin: 0;

    font-size: 34px;
}

.title_container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.close svg
{
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.close svg:hover
{
    color: var(--btnColor)

}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .close
    {
        position: absolute;
        top: 0;
        right: 0;
    }
}
