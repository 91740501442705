    :root
    {
        --background: rgb(22, 22, 26);
        --navbarColor: #3AA935;
        --btnColor: #3aa935;
        --btnColorHover: #1e5c1c;
        --btnColorDisabled: rgba(58, 169, 53, 0.7);

        --error: #C6394A;
        --warning: #E6BD19;
        --success: #1EA966;

        --borderGrey: #D1D1D1;
    }
