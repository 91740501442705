.control_panel_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;
}

.control_panel_container h1
{
    text-align: center;
}

.list_selection_container
{
    display: flex;
    align-items: center;
    gap: 10px;
}

.list_selection_container a
{

    border: 1px solid #3aa935;
    border-radius: 2em;
    padding: .3em 1em;

    text-decoration: none;
    text-align: center;
    cursor: pointer;

    transition: background-color .5s;
}

.list_selection_container a:hover
{
    background: #1e5c1c;
    color: #fff;
    border-color: #1e5c1c;
}

.selected
{
    background: #3aa935;

    color: #fff;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .list_selection_container
    {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
}
