body
{
  margin: 0;
  font-family: Fira Sans, sans-serif;
  color: #3c3c3b;
  line-height: 1.5;
}
code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container
{
  max-width: 1470px;

  padding: 10px;

  box-sizing: border-box;
}

.notification_button
{
  display: flex;
  justify-content: center;

  margin-bottom: 10px;
}

.notification_button a
{
  padding: .5rem;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
  .main-container
  {
    width: 100vw;

    padding: 10px;
    box-sizing: border-box;
  }
}
