.branch_container
{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    gap: 10px;
}

.qr_code_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.branch_info
{
    display: flex;
    flex-direction: column;
}

.visitor_registration
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 364px;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .visitor_registration
    {
        width: 100%;
    }
}
