.list_container
{
    display: flex;
    flex-direction: column;

    gap: 10px;

    width: 100%;
    box-shadow: 0 0 20px rgba(212,216,232,.60);
    border-radius: 10px;
}

table
{
    text-align: center;

    width: 100%;
    border-collapse: collapse;
    padding: 20px;
}

thead
{
    background: rgba(0, 0, 0, 0.1);
}

.qr_code
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 150px;
}

.qr_code svg
{
    width: 150px;
}

.table_controls
{
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;

    gap: 10px;
}

.search
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.search a
{
    padding: 5px 10px;
}

.search input
{
    width: 340px;
    padding: 5px;
    border: 1px solid #707070;
    background-color: #f5f9ff;
}

.filter_controls
{
    display: flex;
    width: 100%;
}

.verify_filter
{
    display: flex;
    align-items: center;
    gap: 10px;
}

.page_sort_controls
{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.branches_title
{
    display: flex;
    align-items: center;
    padding: 20px 20px 10px 20px;
}

.branches_title h3
{
    padding: 0 !important;
}

.add_branch
{
    display: flex;
    justify-content: flex-end;

    width: 100%;
}

th
{
    width: 500px;
    cursor: pointer;

    user-select: none;
}

td
{
    word-break: break-word;
}

th div
{
    display: flex;
    justify-content: center;
    align-items: center;
}

th svg
{
    width: 20px;
    height: 20px;
}

.select_all
{
    width: 50px;
}

tr
{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

td, th
{
    padding: 5px;
}

tr:last-child
{
    border: none;
}

.list_container h3
{
    margin: 0;
    padding-top: 20px;
    padding-left: 20px;
    text-align: left;
}

.admin_controls_main
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.admin_controls
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.admin_controls svg
{
    width: 20px;
    height: 20px;
}

.delete_btn
{
    display: flex;
    padding: 0 20px 20px 0;
    justify-content: flex-end;
}

.verified_view_more
{
    all: unset;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    cursor: pointer;
}

.verified_view_more:hover
{
    color: #1e5c1c;
}

.verified_view_more svg
{
    width: 25px;
    height: 25px;
}

.verified_info
{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.verified_info span
{
    font-weight: bold;
}

.unverified a
{
    color: #000;
    border: 2px solid;
}

.items_controls
{
    padding: 10px;

    border: 1px solid var(--btnColor);
    border-radius: 2em;

    background: transparent;
    color: #000;

    cursor: pointer;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .list_container
    {
        width: 100%;
    }

    thead
    {
        display: none;
    }

    tr
    {
        display: flex;
        flex-direction: column;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    tr:nth-child(even)
    {
        background-color: rgba(0, 0, 0, 0.3);
    }

    td
    {
        font-size: .8em;
        text-align: right;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    td::before
    {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .list_container h3
    {
        padding-left: 5px;
    }

    .delete_btn
    {
        padding-right: 5px;
    }

    .table_controls
    {
        padding: 5px;
        flex-direction: column;
        gap: 10px;
    }

    .branches_title
    {
        padding: 5px;
    }

    .search
    {
        padding-bottom: 5px;
    }

    .search input
    {
        width: 60%;
    }

    .add_branch
    {
        justify-content: flex-end;

        width: 100%;
    }

    .admin_controls
    {
        flex-direction: row;
    }

    .verified_view_more, .admin_controls_main, .verified_info
    {
        justify-content: flex-end;
        align-items: flex-end;
    }

    .filter_controls
    {
        align-items: flex-end;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .list_container
    {
        width: 100%;
    }

    .admin_controls a
    {
        font-size: 0.95em;
    }
}
