.form
{
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;

    text-align: left;
}

.form_content_container
{
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 30px;
}

.form_default_options_container, .host_admin_form_options
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form span
{
    width: 100%;
}

.form_edit
{
    padding: 30px;
    background: #fff;
    color: #000;
    border-radius: 10px;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.form label
{
    display: flex;
    flex-direction: column;

    width: 100%;
}

.form input
{
    width: 340px;
    padding: 10px;
    border: 1px solid #707070;
    background-color: #f5f9ff;
}

.form input[type="submit"]
{
    background-color: var(--btnColor);
    color: #fff;

    font-weight: 700;
    font-size: 1.2em;

    padding: .7em 1em;

    border: none;
    border-radius: 2em;

    cursor: pointer;
    transition: background-color .5s;
}

.form input[type="submit"]:hover
{
    background-color: var(--btnColorHover);
}

.terms, .document_check
{
    display: flex;
    flex-direction: row !important;

    width: 100%;
    gap: 10px;
}

.form input[type="checkbox"]
{
    all: unset;
    margin-top: 3px;
}

.form input[type="checkbox"]::before
{
    content: '';
    display: inline-flex;
    width: 1em;
    height: 1em;

    border: 1px solid #707070;
    background-color: #f5f9ff;
}

.form input[type="checkbox"]:checked::before
{
    clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%);
    background-color: var(--btnColor);
}

.form input[type="checkbox"]:disabled::before
{
    background-color: #707070;
}

.edit_form_container
{
    display: flex;
    position: fixed;

    z-index: 5;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0,0,0,.5);
}

.edit_form
{
    display: flex;
    align-items: center;
    position: relative;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
}

.host_admin_form_options select
{
    width: 361px;
    padding: 10px;
    border: 1px solid #707070;
    background-color: #f5f9ff;
}

.document_check_other
{
    display: flex;
}

.document_check_other span
{
    width: fit-content;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .form
    {
        width: 90vw;
        max-height: 80vh;

        overflow-y: scroll;
    }

    .form input
    {
        width: 300px;
    }

    .host_admin_form_options select
    {
        width: 320px;
    }

    .form_edit
    {
        padding: 10px;
    }

    .form_content_container
    {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }

    .host_admin_form_options
    {
        align-items: flex-start;
    }

    .document_check_other
    {
        display: flex;
        flex-direction: column !important;
    }

    .form input[type="submit"]
    {
        padding: 8px;
    }
}
