header
{
    display: flex;
    justify-content: center;

    width: 100%;

    background: var(--navbarColor);

    margin-bottom: 50px;
}

.navbar_container
{
    display: flex;
    align-items: center;

    width: 1470px;

    padding: 10px;
    gap: 50px;
}

.navbar_container svg
{
    width: 104px;
    height: 23px;
    fill: #fff;
}

.controls_container
{
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
}

.controls_container span
{
    display: none;
    cursor: pointer;
}

.nav_menu
{
    display: flex;
    width: 100%;
}

.navigation
{
    display: flex;
    align-items: center;
    gap: 10px;

    width: 50%;
}

.controls
{
    display: flex;
    justify-content: flex-end;

    width: 50%;

    gap: 10px;
}

.controls select
{
    padding: 10px;

    border: 2px solid #fff;
    border-radius: 2em;

    background: transparent;
    color: #fff;

    cursor: pointer;

    font-weight: 700;
    font-size: .9em;
}

.nav_user_controls
{
    display: flex;
    align-items: center;
    width: 100%;

    justify-content: flex-end;
    gap: 58px;
}


.user_lang_container
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.language_selections
{
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;

    padding: 4px 8px;

    color: #fff;
}

.language_selections hr
{
    height: 15px;
}

.language_selections button
{
    all: unset;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
}

.selected_language
{
    font-weight: 600 !important;
    text-decoration: underline !important;
}

.user_name
{
    display: flex !important;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    header
    {
        height: 50px;
        margin-bottom: 10px;
    }

    .navbar_container img
    {
        width: 100px;
    }

    .navbar_container
    {
        padding: 15px;
    }

    .controls_container span
    {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .controls_container span svg
    {
        width: 24px;
        height: 24px;

        color: #fff;
    }

    .nav_menu:not(.open)
    {
        display: none;
    }

    .nav_menu
    {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;

        top: 50px;
        right: 0;

        padding-top: 10px;
        padding-right: 15px;

        width: 100%;
        height: 100%;

        background: rgba(0,0,0, .8);

        gap: 10px;
    }

    .controls, .navigation
    {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: fit-content;
    }

    .navigation a
    {
        border: 2px solid #fff;
    }

    .controls option
    {
        background: #fff;
        color: #000;
    }

    .nav_user_controls
    {
        flex-direction: column;
        align-items: flex-end;

        gap: 10px;
    }

    .nav_user_controls option
    {
        background: #fff;
        color: #000;
    }

    .nav_user_controls select
    {
        color: #fff;

        border: 2px solid #fff;
    }

}
