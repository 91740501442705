.visitor_approval_main
{
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
}

.status i
{
    white-space: pre-line
}

.refresh
{
    display: flex;
    justify-content: center;
    flex-direction: column;

    min-width: 220px;
}

.refresh span
{
    font-size: 13px;
}

.approval_title
{
    display: flex;flex-direction: column;
}

.approval_title span
{
    font-size: 18px;
    font-weight: 300;
}