footer
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: 5px;
}


footer a
{
    text-decoration: none;
    color: #000000;
}

footer a:hover
{
    color: var(--btnColor);
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    footer
    {
        text-align: center;
    }
}
