.statistics_container
{
    display: flex;
    flex-direction: column;
    width: 1470px;

    gap: 50px;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .statistics_container
    {
        width: 90vw;
    }
}
