@import '../../helpers/Colors.css';

.btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    text-decoration: none;
    border-radius: 2em;
    text-align: center;
    cursor: pointer;

    color: #fff;
    font-weight: 700;
    transition: all .5s;

    word-break: keep-all;
}

.primary
{
    background: var(--btnColor);
}

.primary-white
{
    background: #fff;
    color: #000;
}

.secondary
{
    color: #fff;

    border: 2px transparent;
}

.secondary:hover
{
    border-color: var(--btnColorHover);
}

.delete-btn
{
    background: var(--error);
}

.delete-btn:hover
{
    background: var(--error) !important;
    filter: brightness(90%);
}

.btn:hover
{
    background: var(--btnColorHover);
    color: #fff;
}

.btn-lg
{
    padding: .7em 1em;
    font-size: 1.2em;
}

.btn-sm
{
    padding: .4em 0.5em;
    font-size: 1.1em;
}


.disabled, .disabled:hover
{
    filter: brightness(85%);
    color: #fff;
}

.selected
{
    background: var(--btnColorHover);
    border-color: var(--btnColorHover);
    color: #fff;
}

.optional
{
    color: #000;

    border: 1px solid #3aa935;
    border-radius: 2em;
    padding: .3em 1em;

    text-decoration: none;
    text-align: center;
    cursor: pointer;

    transition: background-color .5s;
}
